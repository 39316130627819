body {
  align-items: center;
  background-color: #3a3975;
  background-image: linear-gradient(71deg, #0a0322, #2b2559);
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  background-position: 50%;
  background-size: cover;
  height: 56.25em;
  overflow: hidden;
  position: relative;
  width: 100%;
}

[data-orientation='landscape'] #root {
  height: 100%;
  width: 100.25em;
}
